export const appBGPalette = {
    'bg-0-0': '#e6e6e6',
    'bg-1-0': '#e6f6cf',
    'bg-2-0': '#d8f7f3',
    'bg-3-0': '#e0f1fb',
    'bg-4-0': '#fce5f1',
    'bg-5-0': '#ffee9c',
    'bg-6-0': '#f7e9c1',
    'bg-0-1': '#bababa',
    'bg-1-1': '#b7e281',
    'bg-2-1': '#92e1d5',
    'bg-3-1': '#a6e0fc',
    'bg-4-1': '#ffc8ea',
    'bg-5-1': '#fed74a',
    'bg-6-1': '#e0c378',
    'bg-0-2': '#878787',
    'bg-1-2': '#7dbd36',
    'bg-2-2': '#25beb2',
    'bg-3-2': '#42a3df',
    'bg-4-2': '#ff7bc3',
    'bg-5-2': '#ff7123',
    'bg-6-2': '#ce6700',
    'bg-0-3': '#4d4d4d',
    'bg-1-3': '#409600',
    'bg-2-3': '#2f9890',
    'bg-3-3': '#0070e4',
    'bg-4-3': '#dc0083',
    'bg-5-3': '#e30000',
    'bg-6-3': '#8d5100',
    'bg-0-4': '#1a1a1a',
    'bg-1-4': '#246512',
    'bg-2-4': '#00665e',
    'bg-3-4': '#0050a1',
    'bg-4-4': '#900052',
    'bg-5-4': '#8e1600',
    'bg-6-4': '#553000',
}

export function useAppPalette() {
    const headTag = document.getElementsByTagName('head')[0]
    const styleTag = document.createElement('style')
    styleTag.innerHTML = `
:root {
    ${Object.entries(appBGPalette).map(([key, val]) => (`--${key}: ${val}`)).join(';\n')}
}
`
    headTag.appendChild(styleTag)
}
